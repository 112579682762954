import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as T } from 'react-intl';

import { Image, Layout, Link, SectionResultsQuote, SectionSteps, SectionTextMedia, SEO, TrustpilotWidget } from 'components';
import { routes } from 'utils/routes';

import content from './index.yml';

const IndexPage = ({ pageContext: { locale }, location }) => (
  <Layout locale={locale}>
    <SEO
      title={content.title}
      description={content.description}
      lang={locale}
      keywords={content.keywords}
      location={location}
      image={content.socialImage}
    />

    <Link to={routes.apart.path} {...routes.apart} className='cta-sidetab'>What sets us apart?</Link>

    <div className='cover-home fvw'>
      <div className='cover content-wrapper'>
        <Image url='pages/home_cover.jpg' alt='Invisible aligners for your ideal smile' className='section-media' />
        <div className='section-text'>
          <h1>Your ideal smile.<br/>Powered by high-tech, affordable orthodontics.</h1>
          <p className='mt-20'>Put your trust, and your smile, in the hands of the experts.<br/>Fully tracked treatment and guaranteed results in 5-9 months.<br/>At a fraction of the clinic cost.</p>
          <div className='flex-vam cover-home-actions'>
            <Link to={routes.getStarted.path} {...routes.getStarted} className='button'>Get started</Link>
            <Link to={routes.homeVideo.path} {...routes.homeVideo} className='button arrow-next'>Watch video<div className='button-arrow'/></Link>
          </div>
        </div>
        <Image url='icons/icon_stamp.svg' alt='Straight teeth direct stamp of guarantee' className='stamp' />
      </div>
    </div>

    <div className='section-compare-pricing fvw'>
      <div className='content-wrapper'>
        <div className='info-pricing'>
          <p className='t-center'>Our price <T id="index.ourPrice">{txt => <b className='large ml-5 mr-5'>{txt}</b>}</T></p>
          <span className='versus'>vs</span>
          <p className='t-center'><s>Clinic price</s> <s className='ml-5 mr-5'><T id="index.clinicPrice">{txt => <b>{txt}</b>}</T></s></p>
        </div>
        <TrustpilotWidget />
      </div>
    </div>

    <SectionTextMedia
      title='Data-driven smiles – from our lab to your door'
      text="Your treatment will be tailored and your progress tracked by an expert orthodentist using data personal to you. All through our game-changing app that's built on years of research."
      button={{ label: 'View pricing', ...routes.pricing, level: 'outline' }}
      image={{ url: 'pages/home_datadriven.jpg', alt: 'Data driven smiles' }}
      className='bg-img-grid-bl'
    />

    <SectionSteps />

    <div className='section-reasons fvw'>
      <div className='content-wrapper'>
        <h3 className='section-title mb-50'>3 Reasons to choose<br/>Straight Teeth Direct&trade;</h3>
        <div className='section-icons'>
          <div className='icon-wrapper'>
            <Image url='icons/icon_accessible.svg' alt='Invisible aligners delivered to your door' />
            <h4>Accessible & convenient</h4>
            <p>From our high-quality products to hassle-free delivery, we look after the entire process, which means we can pass on savings to you and offer a seamless service.</p>
          </div>
          <div className='icon-wrapper'>
            <Image url='icons/icon_experts.svg' alt='Invisible aligners by honest experts' />
            <h4>Honest experts</h4>
            <p>We’re led by research and data, so our orthodontists won’t promise everyone a Hollywood smile. You’ll get personal, affordable treatment and the support you need to get your ideal smile.</p>
          </div>
          <div className='icon-wrapper'>
            <Image url='icons/icon_innovating.svg' alt='Always innovating to create your best smile' />
            <h4>Always innovating</h4>
            <p>We constantly push the limits. From 3D manufacturing to innovative tracking apps, we’re always looking for ways to advance dentistry, improve your experience and stay affordable.</p>
          </div>
        </div>
        <div className='flex-center mt-50'>
          <Link to={routes.faq.path} {...routes.faq} className='button ghost'>Check out FAQ's</Link>
        </div>
      </div>
    </div>

    <SectionTextMedia
      title='Our promise'
      subtitle="It's simple. Guaranteed results with no hidden costs."
      text="With your smile simulation, tracked treatment and four-step guarantee there are no hidden surprises or unexpected costs."
      button={{ label: 'Check out the guarantees', ...routes.guarantees, level: 'outline' }}
      image={{ url: 'pages/home_promise.jpg', alt: 'Our promise' }}
      stamp
      className='bg-img-grid-large'
    />

    <SectionResultsQuote />

    <div className='section-icons-press'>
      <h2 className='section-title'>In the press</h2>
      <div className='icons-press-wrapper'>
        <Link to={routes.dailymirror.path} {...routes.dailymirror} className='icon-press'><Image url='logos/logo_dailymirror.png' alt='Daily Mirror logo' /></Link>
        <Link to={routes.inc.path} {...routes.inc} className='icon-press'><Image url='logos/logo_inc.png' alt='Inc logo' className='w13' /></Link>
        <Link to={routes.ft.path} {...routes.ft} className='icon-press'><Image url='logos/logo_ft.png' alt='Financial Times logo' className='w5' /></Link>
        <Link to={routes.mhealth.path} {...routes.mhealth} className='icon-press'><Image url='logos/logo_mhealth.png' alt='The journal of mHealth logo' className='w16' /></Link>
        <Link to={routes.independent.path} {...routes.independent} className='icon-press'><Image url='logos/logo_independent.png' alt='The independent logo' className='w22' /></Link>
        {/*<Link to={routes.marieclaire.path} {...routes.marieclaire} className='icon-press'><Image url='logos/logo_marieclaire.png' alt='Marie claire logo' className='w18' /></Link>*/}
        <span className='icon-press'><Image url='logos/logo_marieclaire.png' alt='Marie claire logo' className='w18' /></span>
        <Link to={routes.stylist.path} {...routes.stylist} className='icon-press'><Image url='logos/logo_stylist.png' alt='Stylist magazine logo' className='w16' /></Link>
        <Link to={routes.sundaytimes.path} {...routes.sundaytimes} className='icon-press'><Image url='logos/logo_sundaytimes.png' alt='The Sunday Times logo' className='w19' /></Link>
        <Link to={routes.thesun.path} {...routes.thesun} className='icon-press'><Image url='logos/logo_thesun.png' alt='The Sun logo' className='w14' /></Link>
      </div>
      {/*<Link to={routes.press.path} {...routes.press} className='button outline'>See more press</Link>*/}
    </div>

    <SectionTextMedia
      title='The revolutionary app.'
      subtitle='The only app to manage your entire teeth-straightening journey.'
      text="Our app is like having a dentist in your pocket. From the initial consultation to monthly progress reviews and managing your next batch of custom aligners – it’s all simple, easy and overseen by our expert team."
      button={{ label: 'About us', ...routes.about, level: 'outline' }}
      image={{ url: 'pages/home_app.png', alt: 'Start your free e-consultation' }}
      className='no-margin mobile-reverse'
    />
  </Layout>
);

IndexPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
};

export default IndexPage;
